import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { GameConfig, LevelItem, WinningItem } from "./GameConfig";
import styles from "./index.module.scss";
import { RegisterForm } from "../../components";
import MemoryGame from "./components/MemoryGame";
import { shuffleArray } from "./utils";
import { useGameCanPlay } from "../../api/useGameSessions";

export const MemoCards = (props: any) => {
  const [userData, setuserData] = useState(null as any);
  const [closeFrom, setCloseFrom] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [selectedLevel, setSelectedLevel] = useState<number>(-1);
  const [gameConfig, setGameConfig] = useState({
    start: false,
    cards: [] as Array<any>,
    memoCardsConfig: {},
    subscription: {},
    level: {},
  });

  const [cantPlay, setCantPlay] = useState({
    value: false,
    message: "",
    setted: false,
  });

  const customConfig = props.gameSession.customGameConfig.game_config;
  const memoCardsConfig = {
    ...customConfig,
  } as GameConfig;
  let cardsPool = memoCardsConfig.cards;
  cardsPool = shuffleArray(cardsPool);

  const {
    data: canPlay,
    isLoading,
    error,
    isSuccess,
  } = useGameCanPlay({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    playerPhone: userData?.phone,
    playerEmail: userData?.email,
    gameCycle: props.gameSession.customGameConfig.game_config.gameTimeCycle,
  });

  useLayoutEffect(() => {
    if (ref.current) {
      if (memoCardsConfig.backgroundColor) {
        ref.current.style.setProperty(
          "background-color",
          memoCardsConfig.backgroundColor,
          "important"
        );
      }
      if (memoCardsConfig.backgroundImage) {
        let tmpStyleBg = `url("${memoCardsConfig.backgroundImage}")`;
        ref.current.style.setProperty(
          "background-image",
          tmpStyleBg,
          "important"
        );
        ref.current.style.setProperty("background-size", "cover", "important");
        ref.current.style.setProperty(
          "background-position",
          "top center",
          "important"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess && !canPlay) {
      setCantPlay({
        value: true,
        message: "Tu as déjà joué",
        setted: true,
      });
    }
    if (selectedLevel !== -1 && canPlay) {
      let cardsToPass = cardsPool.slice(
        0,
        memoCardsConfig.levels[selectedLevel].cardsNumber - 1
      );
      setGameConfig({
        cards: cardsToPass,
        start: true,
        memoCardsConfig: memoCardsConfig,
        subscription: props.gameSession.subscription,
        level: memoCardsConfig.levels[selectedLevel],
      });
    }
  }, [selectedLevel, isSuccess]);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.intro}>
        {memoCardsConfig.text && !gameConfig.start &&
          memoCardsConfig.text.map((tx, i) => <h4 key={i}>{tx.content}</h4>)}
      </div>
      <div className={styles.button_container}>
        {selectedLevel === -1 && !cantPlay.value ? (
          <>
            {memoCardsConfig.levels.map((level, index) => (
              <button key={index} onClick={() => setSelectedLevel(index)}>
                {level.name}
              </button>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.game_container}>
        {cantPlay.value && cantPlay.setted && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {cantPlay.message} —
          </Alert>
        )}
        {gameConfig.start === true && selectedLevel !== -1 ? (
          <MemoryGame {...gameConfig} userData={userData} />
        ) : null}
      </div>
      <div className={styles.gamebox}>
        <RegisterForm
          getData={setuserData}
          closeForm={closeFrom}
          formTitle={memoCardsConfig.formTitle || undefined}
          description={memoCardsConfig.formDescription || undefined}
          additionalContents={memoCardsConfig.text || undefined}
          customStyle={{ mainColor: memoCardsConfig.backgroundColor }}
        />
      </div>
    </div>
  );
};
