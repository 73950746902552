import React from "react";
import styles from "./SingleCard.module.scss";
export default function SingleCard(props: any) {
  const handleClick = () => {
    props.handleChoice(props.card);
  };
  return (
      <div className={styles.card}>
        <div className={props.flipped ? styles.flipped : ''}>
          <img
            className={styles.front}
            src={props.flipped ? props.card.imageFr : ''}
            alt="card front"
          />
          <img
            className={styles.back}
            src={props.cardBack}
            alt="card back"
            onClick={handleClick}
          />
        </div>
      </div>
  );
}
