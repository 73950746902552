import { useQuery } from "react-query";
import { useHistory } from "react-router";
import supabase from "../config/supabase";
import useGames from "./useGetGames";

export interface SubscriptionGetProps {
  appId: string | null;
  apiKey: string | null;
}

export interface CheckSubscriptionProps extends SubscriptionGetProps {
  gameName: string | null;
}

const getSubscription = async ({ appId, apiKey }: SubscriptionGetProps) => {
  const { data, error } = await supabase
    .from("subscriptions")
    .select()
    .eq("app_id", appId)
    .eq("api_key", apiKey)
    .single();

  if (error) {
    // .single will cause top level error
    throw new Error(error.message);
  }

  if (!data) {
    // better handler throw does not give any context
    throw new Error("subscription not found");
  }

  return data;
};

export default function useSubscription({
  appId,
  apiKey,
  gameName,
}: CheckSubscriptionProps) {
  const history = useHistory();
  const {
    data: gamesData,
  } = useGames();

  // "!!gamesData" being added to avoid run without data for next hook
  return useQuery(
    "subscription",
    () =>
      getSubscription({ appId, apiKey }).then((data) => {
        // map game name to id
        let currentGame = gamesData?.filter((x) => x.label === gameName)[0];
        return { subscriptionData: data, gameData: currentGame };
      }),
    {
      enabled: !!appId && !!apiKey && !!gameName && !!gamesData,
      onSuccess: (data: any) => {
        if (!data?.subscriptionData?.allowed_apps?.includes(data.gameData.id)) {
          // force redirect
          return history.push("/notAllowed");
        }
      },
      onError: (err: any) => {
        return history.push("./error");
      },
    }
  );
}
