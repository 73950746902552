import "./index.css";
import React, { useEffect, useRef, useState } from "react";
import useSaveGameSession from "../../api/useSaveGameSession";

function Slots({
  postPlay,
  kind,
  slotsMachineConfig,
  canWin,
  gameSession,
  userData,
  setPrize,
}: any) {
  const prizesPool = [...slotsMachineConfig.slotsItem];
  const [slot1, setSlot1] = useState(prizesPool[0]);
  const [slot2, setSlot2] = useState(prizesPool[0]);
  const [slot3, setSlot3] = useState(prizesPool[0]);
  const [rolling, setRolling] = useState({ rolling: false, it: 0 });
  let slotRef = [useRef(null), useRef(null), useRef(null)];
  const [prizeIndex, setPrizeIndex] = useState(-2);
  const [isWinner, setIsWinner] = useState(false);
  const {
    data: gameSaveData,
    isLoading: isLoadingSave,
    error: errorSave,
    isSuccess: isSuccessSave,
    mutate: saveGameSession,
  } = useSaveGameSession();

  useEffect(() => {
    if (rolling.rolling === false && rolling.it === 2) {
      // save the game
      saveGameSession({
        subscription_id: gameSession.subscription.subscriptionData.id,
        game_id: gameSession.subscription.gameData.id,
        player_name: userData?.firstName,
        player_last_name: userData?.lastName,
        player_phone: userData?.phone,
        is_winner: isWinner,
        winning_prize_id: slotsMachineConfig?.winningItems[prizeIndex]?.itemId,
        winning_prize_name:
          slotsMachineConfig?.winningItems[prizeIndex]?.itemName,
        winning_addional_data: {
          item_link:
            slotsMachineConfig?.winningItems[prizeIndex]?.itemFullText || "",
        },
      });
      postPlay({
        play: true,
        kind: isWinner ? "win" : "lose",
      });
    }
  }, [rolling]);

  // to trigger roolling and maintain state
  const roll = () => {
    setRolling({ rolling: true, it: 0 });
    setTimeout(() => {
      setRolling({ rolling: false, it: 1 });
    }, 700);

    // looping through all 3 slots to start rolling
    slotRef.forEach((slot, i) => {
      // this will trigger rolling effect
      const selected = triggerSlotRotation(slot.current);
      if (i === 0) setSlot1(selected);
      else if (i === 1) setSlot2(selected);
      else setSlot3(selected);
    });
  };

  useEffect(() => {
    if (rolling.rolling === false && rolling.it === 1) {
      if (slot1.itemId === slot2.itemId && slot2.itemId === slot3.itemId) {
        const newPrizeNumber = Math.floor(
          Math.random() * slotsMachineConfig.winningItems.length
        );
        setPrizeIndex(newPrizeNumber);
        setPrize(newPrizeNumber);
        setIsWinner(true);
      }
      setRolling({ rolling: false, it: 2 });
    }
  }, [rolling]);

  // this will create a rolling effect and return random selected option
  const triggerSlotRotation = (ref: any) => {
    function setTop(top: any) {
      ref.style.top = `${top}px`;
    }
    let options = ref.children;
    let pool = prizesPool;
    let randomOption = Math.floor(Math.random() * pool.length);
    if (!canWin) {
      while (
        prizesPool[randomOption].itemId === slot1.itemId &&
        prizesPool[randomOption].itemId === slot2.itemId
      ) {
        randomOption = Math.floor(Math.random() * pool.length);
      }
    }
    let choosenOption = options[randomOption];
    setTop(-choosenOption.offsetTop + 2);
    return pool[randomOption];
  };

  return (
    <div className="slot_machine_big">
      <div className="logo_container" >
        <img src={slotsMachineConfig?.logos?.[1]} alt="" className="logo" />
      </div>
      <div className="game_big_box">
        <div className="game_slot_header"></div>
        <div className="game_slot_plat"></div>
      <div className="slot_container">
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[0]}>
                {prizesPool.map((prize, i) => (
                  <div key={"s0" + prize.itemId} className="box">
                    <img src={prize.itemImage} alt="" />
                    {/* <span>{prize.itemName}</span> */}
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[1]}>
                {prizesPool.map((prize) => (
                  <div key={"s1" + prize.itemId} className="box">
                    <img src={prize.itemImage} alt="" />
                    {/* <span>{prize.itemName}</span> */}
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[2]}>
                {prizesPool.map((prize) => (
                  <div key={"s2" + prize.itemId} className="box">
                    <img src={prize.itemImage} alt="" />
                    {/* <span>{prize.itemName}</span> */}
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
        <div
            className={!rolling.rolling ? "roll" : "roll rolling"}
            onClick={() => {
              !rolling.rolling && roll();
            }}
            //disabled={rolling}
          >
            {/* {rolling.rolling ? "Rolling..." : "ROLL"} */}
            <div className="here">▼</div>
          </div>
          <div className="white-space"></div>
      </div>
        
    </div>
  );
}

export default Slots;
