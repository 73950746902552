import { RouteProps, Switch, useHistory, useLocation } from "react-router-dom";
import useGameConfig from "../../api/useGetGameConfig";
import useSubscription from "../../api/useGetSubscription";
import { RouteWithSubRoutes } from "../../components/RouteWithSubRoutes";
import styles from "./index.module.scss";
import { cacheImage, useQuery } from "../../utils";
import Loader from "../../components/Loader";
import logo from "../../assets/superverse_logo.png";
import { useEffect, useState } from "react";

interface GamesProps {
  routes: Array<RouteProps>;
}

export const Games = ({ routes }: GamesProps) => {
  let query = useQuery();
  let history = useHistory();
  let location = useLocation();
  let gameName = location?.pathname?.split("/")[2] || "";
  // run all checks here
  const [imageCachingDone ,setImageCachingDone] = useState(false);
  const appId = query.get("appId");
  const apiKey = query.get("apiKey");
  const {
    isLoading,
    isError,
    isIdle,
    data: subsData,
  } = useSubscription({
    appId: appId,
    apiKey: apiKey,
    gameName: gameName,
  });

  const {
    isLoading: gameConfigLoading,
    isError: gameConfigError,
    isIdle: gameConfigIdle,
    data: gameConfigData,
    isSuccess
  } = useGameConfig({
    subscriptionId: subsData?.subscriptionData?.id,
    gameId: subsData?.gameData?.id,
  });

  const doneLoading = () => setImageCachingDone(true);

  useEffect(() => {
    if(isSuccess && ["scratchCards","memoCards","classicMemo"].includes(gameName)) {
      // collect all images and cache them
      // for cards
      let imagesCards = gameConfigData?.game_config?.cards?.map((card: any) => card.imageURL) || [];
      // for scratch
      let imagesItems = gameConfigData?.game_config?.items?.map((item: any) => {
        if(item?.itemImage) {
          return item.itemImage;
        } else {
          return "";
        }
      }) || [];
      console.log('imagesCards -> ', imagesItems)
      let bgOfGame = gameConfigData?.game_config?.backgroundImage;
      const toCache = [...imagesCards, ...imagesItems.filter((x: string) => x && x.length > 0), bgOfGame];
      cacheImage(toCache, doneLoading);
    } else if(isSuccess && !["scratchCards","memoCards","classicMemo"].includes(gameName)) {
      doneLoading();
    }

  }, [isSuccess]);

  if (!gameName || gameName.length === 0) {
    history.push("/404");
    return <div>missing...</div>;
  }

  if (!appId || !apiKey) {
    history.push("/notAllowed");
    return <div>missing...</div>;
  }

  if (isLoading || !imageCachingDone || isIdle || gameConfigIdle || gameConfigLoading) {
    return <Loader />
  }

  if (isError || gameConfigError) {
    history.push("/error");
    return <div>error</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.gamehost}>
        <Switch>
          {routes.map((route: any, i: number) => (
            <RouteWithSubRoutes
              key={i}
              {...route}
              gameSession={{
                customGameConfig: gameConfigData,
                defaultGameConfig: subsData.gameData.default_config,
                subscription: subsData,
              }}
            />
          ))}
        </Switch>
      </div>
      <div className={styles.footer}>
        <span>POWERD BY</span>
        <img src={logo} height={40} alt="superverse entreprise"/> 
      </div>
    </div>
  );
};
