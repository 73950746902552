import React from "react";
import styles from "./index.module.scss"

import lost from "./lost.gif";
export const NoMatch = () => {
  return (
    <div className={styles.container}>
      <img src={lost} alt="lost" />
      <p>We are lost buddy...</p>
    </div>
  );
};
