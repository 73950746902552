import React from "react";
import styles from "./index.module.scss";
import error from "./500.gif"

export const Error = () => {
  
  return (
    <div className={styles.container}>
      <img src={error} alt="" />
      <p>500!! , probably piopa broke the server</p>
    </div>
  );
};
