import {
  Error,
  FortuneWheel,
  Games,
  Health,
  MemoCards,
  NoMatch,
  NotAllowedScreen,
  ScratchCards,
} from "../screens";
import Slots from "../screens/SlotsMachine";
import ClasicMemory from "../screens/ClassicMemo";

const routes = [
  {
    path: "/health",
    component: Health,
  },
  {
    path: "/games",
    component: Games,
    routes: [
      {
        path: "/games/fortuneWhell",
        component: FortuneWheel,
      },
      {
        path: "/games/scratchCards",
        component: ScratchCards,
      },
      {
        path: "/games/memoCards",
        component: MemoCards,
      },
      {
        path: "/games/slotsMachine",
        component: Slots,
      },
      {
      // {
      //   path: "/games/slotsMachine",
      //   component: SlotsMachine,
      // }
        path: "/games/classicMemo",
        component: ClasicMemory,
      }
    ],
  },
  {
    path: "/notallowed",
    component: NotAllowedScreen,
  },
  {
    path: "/404",
    component: NoMatch,
  },
  {
    path: "/error",
    component: Error,
  },
  {
    path: "/",
    component: Health,
    exact: true
  },
  {
    path: "*",
    component: NoMatch,
  },
];

export default routes;
