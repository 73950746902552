import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { GameConfig } from "./GameConfig";
import { useGameCanPlay, useGameWins } from "../../api/useGameSessions";
import useSaveGameSession from "../../api/useSaveGameSession";
import styles from "./index.module.scss";
import sad from "./sad.svg";
import smile from "./smile.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { RegisterForm } from "../../components";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Slots from "./Slots";
import Loader from "../../components/Loader";

export const SlotsMachine = (props: any) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const ref = useRef<HTMLDivElement>(null);
  const [userData, setuserData] = useState(null as any);
  const [closeFrom, setCloseFrom] = useState(false);
  const [prizeIndex, setPrizeIndex] = useState(-1);
  const [canWin, setCanWin] = useState(false)
  const [cantPlay, setCantPlay] = useState({
    value: false,
    message: "",
    setted: false,
  });
  const [postAnimation, setPostAnimation] = useState({ play: false, kind: "lose" });
  // get all config and set the game to start
  // build config for wheel from default and custom
  const defaultConfig = props.gameSession.defaultGameConfig;
  const customConfig = props.gameSession.customGameConfig.game_config;
  const slotsMachineConfig = {
    ...defaultConfig,
    ...customConfig,
  } as GameConfig;
  useLayoutEffect(() => {
    if (ref.current) {
      if (slotsMachineConfig.backgroundColor) {
        ref.current.style.setProperty(
          "background-color",
          slotsMachineConfig.backgroundColor,
          "important"
        );
      }
      // if (slotsMachineConfig.backgroundImage) {
      //   let tmpStyleBg = `url("${slotsMachineConfig.backgroundImage}")`;
      //   ref.current.style.setProperty(
      //     "background-image",
      //     tmpStyleBg,
      //     "important"
      //   );
      //   ref.current.style.setProperty("background-size", "cover", "important");
      //   ref.current.style.setProperty(
      //     "background-position",
      //     "top center",
      //     "important"
      //   );
      // }
    }
  }, []);
 

  const {
    data: canPlay,
    isLoading,
    error,
    isSuccess,
  } = useGameCanPlay({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    playerPhone: userData?.phone,
    playerEmail: userData?.email,
    gameCycle: slotsMachineConfig.gameTimeCycle as any,
  });

  const {
    data: gameWinsData,
    isLoading: isLoadingWins,
    error: errorWins,
    isSuccess: isSuccessWins,
  } = useGameWins({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    gameCycle: slotsMachineConfig.gameTimeCycle as any,
    canPlay: isSuccess,
  });



  useEffect(() => {
    if (userData && isSuccess && isSuccessWins && slotsMachineConfig.maxWinners) {
      // check if user can play
      if (canPlay) {

        if (slotsMachineConfig.maxWinners  && (gameWinsData?.all || 0) < slotsMachineConfig?.maxWinners
          ) {

            setCanWin(true)
        }
       
      } else {
        // can't even play
        setCantPlay({ value: true, message: "You already played", setted: true });
      }
    }
  }, [userData, gameWinsData, canPlay, isSuccess, isSuccessWins, slotsMachineConfig]);

  const LosePostAnimation = () => {
    return (
      <Dialog
        className={styles.lose}
        open={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={styles.imageHolder}>
            <img src={sad} className={styles.imgSystem} alt="lose" />
          </div>
          <h4>
            {slotsMachineConfig.losePanel && slotsMachineConfig.losePanel.title
              ? slotsMachineConfig.losePanel.title
              : "You was close"}
          </h4>
          <p>
            {slotsMachineConfig.losePanel && slotsMachineConfig.losePanel.content
              ? slotsMachineConfig.losePanel.content
              : "Sorry you was close, but you can try again later."}
          </p>
          {slotsMachineConfig.losePanel && slotsMachineConfig.losePanel.addionalContent ? (
            <p>
              {slotsMachineConfig.losePanel.addionalContent.map((item: any) =>
                item.type === "txt" ? (
                  <>
                    {item.content} <br />
                  </>
                ) : (
                  <>
                    <a
                      href={item.content}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.content}
                    </a>{" "}
                    <br />
                  </>
                )
              )}
            </p>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  };

  const WinPostAnimation = () => {
    let prize = slotsMachineConfig.winningItems[prizeIndex];
    return (
      <>
        <Dialog
          className={styles.win}
          open={true}
          disableEscapeKeyDown={true}
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            {slotsMachineConfig.logos && slotsMachineConfig.logos.length > 0 && (
              <div className={styles.logos}>
                {slotsMachineConfig.logos.map((logo: any, index: any) => (
                  <img key={index} src={logo} alt="company logo" />
                ))}
              </div>
            )}
            <div className={styles.imageHolder}>
              {prize.itemImage ? (
                <img
                  src={prize.itemImage}
                  className={styles.imgProduct}
                  alt="win"
                />
              ) : (
                <img src={smile} className={styles.imgSystem} alt="win" />
              )}
            </div>
            <h4>
              {slotsMachineConfig.winPanelTitle
                ? slotsMachineConfig.winPanelTitle.replace(
                    "$product_name",
                    prize.itemName as string
                  )
                : "You won"}
            </h4>
            <p>
              {slotsMachineConfig.winPanelContents ? (
                slotsMachineConfig.winPanelContents.map((txt: any) => (
                  <>
                    {txt} <br />
                  </>
                ))
              ) : (
                <>
                  Congratulations you won - <b>{prize.itemFullText}</b>
                </>
              )}
            </p>
            <p>
              {slotsMachineConfig.addionalContent
                ? slotsMachineConfig.addionalContent.map((item: any) =>
                    item.type === "txt" ? (
                      <>
                        {item.content} <br />
                      </>
                    ) : (
                      <>
                        <a
                          href={item.content}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.content}
                        </a>{" "}
                        <br />
                      </>
                    )
                  )
                : null}
            </p>
            <p>Nous vous contacterons pour confirmer votre prix.</p>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <div className={styles.container} ref={ref}>

      {postAnimation.play && postAnimation.kind === "win" && (
        <WinPostAnimation />
      )}
      {postAnimation.play && postAnimation.kind === "lose" && (
        <LosePostAnimation />
      )}
      {/* <Animation /> */}
      {!cantPlay.value  && postAnimation.kind && (
        <Slots
          postPlay={setPostAnimation}
          kind={postAnimation.kind}
          slotsMachineConfig={slotsMachineConfig}
          canWin={canWin}
          gameSession={props.gameSession}
          userData={userData}
          setPrize={setPrizeIndex}
        />
      )}
      {cantPlay.value && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {cantPlay.message} —
        </Alert>
      )}
      <RegisterForm
        getData={setuserData}
        closeForm={closeFrom}
        customStyle={{ mainColor: slotsMachineConfig.backgroundColor }}
        formTitle={slotsMachineConfig.formTitle || undefined}
        description={slotsMachineConfig.formDescription || undefined}
        additionalContents={slotsMachineConfig.text || undefined}
      />
      {(isLoading ||
        isLoadingWins )&&
        (  <Loader isTransparent />)}
    </div>
  )
};

export default SlotsMachine;