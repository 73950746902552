import { useMutation, useQueryClient } from "react-query";
import supabase from "../config/supabase";

export interface GameSessionProps {
  player_email?: string;
  player_name: string;
  player_last_name: string;
  player_phone: string;
  game_id: number;
  subscription_id: number;
  is_winner: boolean;
  winning_prize_id?: string | number;
  winning_prize_name: string;
  winning_addional_data?: any;
}

const saveGameSession = async (gameSession: GameSessionProps) => {
  // check if the game config has restriction on the number of play per user
  const { data: sessionInsert, error } = await supabase
    .from("game_sessions")
    .insert([{
      player_email: gameSession.player_email,
      player_name: gameSession.player_name,
      player_last_name: gameSession.player_last_name,
      player_phone: gameSession.player_phone,
      game_id: gameSession.game_id,
      subscription_id: gameSession.subscription_id,
      is_winner: gameSession.is_winner,
      winning_prize_id: gameSession.winning_prize_id,
      winning_prize_name: gameSession.winning_prize_name,
      winning_addional_data: gameSession.winning_addional_data
    }])
  if (error) {
    throw error
  }

  return sessionInsert;
};

export default function useSaveGameSession() {
  return useMutation((gameSession: GameSessionProps) => saveGameSession(gameSession))
}
