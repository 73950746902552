import React from "react";
import styles from "./index.module.scss";
import block from "./not_allowed.svg";

export const NotAllowedScreen = () => {
  return (
    <div className={styles.container}>
      <img src={block} alt="lost" height="500" />
      <p>Looks like your are not allowed here...</p>
    </div>
  );
};
