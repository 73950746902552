import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styles from "./index.module.scss";

interface Props {
  isTransparent?: boolean;
}

const Loader = ({ isTransparent = false }: Props) => {
  return (
    <div className={isTransparent ? styles.loader_transparent : styles.loader}>
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default Loader;
