import React from "react";
// @ts-ignore
import styles from "./index.module.scss";
import {isMobile} from 'react-device-detect';

class ScratchyCard extends React.Component<
  { [key: string]: any },
  { [key: string]: any }
> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  cardRef: React.RefObject<HTMLDivElement | any>;
  constructor(props: any) {
    super(props);

    this.state = {
      isDrawing: false,
      startX: 0,
      startY: 0,
      trans: "",
      scratchedZone: 0,
      end: false,
    };

    this.canvasRef = React.createRef();
    this.cardRef = React.createRef();
  }

  transform = ({ pageX, pageY }: any) => {
    let xAxis = (window.innerWidth / 2 - pageX) / 15;
    let yAxis = (window.innerHeight / 2 - pageY) / 15;
    this.setState({
      ...this.state,
      trans: `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`,
    });
  };

  componentDidMount = () => {
    const canvas = this.canvasRef.current as any;
    const propLocal = this.props;
    if (this.cardRef.current) {
      this.cardRef.current?.node?.addEventListener("mousemove", this.transform);
    }
    if (null !== canvas) {
      const context = canvas.getContext("2d");

      canvas.addEventListener("mousedown", this.scratchStart);
      canvas.addEventListener("mousemove", this.scratch);
      canvas.addEventListener("mouseup", this.scratchEnd);
      // canvas.addEventListener("touchstart", this.scratchStart);
      canvas.addEventListener("touchstart", function (e: any) {
        // touch events not well implimented so we remove the canvas
        if(isMobile){
          canvas.style.width = 0
          setTimeout(() => {
            propLocal.postPlay({ play: true, kind: propLocal.kind });
          }, 500);
        }
      }, false);

      context.fillStyle = "#ddd";
      context.fillRect(0, 0, 360, 50);
      context.lineWidth = 15;
      context.lineJoin = "round";
    }
  };

  componentDidUpdate = () => {
    if (this.canvasRef.current && !this.state.end) {
      let surf =
        this.canvasRef.current.clientHeight *
        this.canvasRef.current.clientWidth;
      if (this.state.scratchedZone >= (surf * 0.5) / 5) {
        this.setState({ ...this.state, end: true });
        this.props.postPlay({ play: true, kind: this.props.kind });
      }
    }
  };

  scratchStart = (e: any) => {
    const { layerX, layerY } = e;
    this.setState({
      isDrawing: true,
      startX: layerX,
      startY: layerY,
      scratchedZone: 0,
    });
  };

  scratch = (e: any) => {
    const { layerX, layerY } = e;
    if (null !== this.canvasRef.current) {
      const context = this.canvasRef.current.getContext("2d");

      if (context) {
        // ts-ignore
        if (!this.state.isDrawing) {
          return;
        }

        context.globalCompositeOperation = "destination-out";
        context.beginPath();
        // ts-ignore
        context.moveTo(this.state.startX, this.state.startY);
        context.lineTo(layerX, layerY);
        context.closePath();
        context.stroke();
        var a = this.state.startX - layerX;
        var b = this.state.startY - layerY;
        var c = Math.hypot(a, b);

        this.setState({
          startX: layerX,
          startY: layerY,
          scratchedZone: this.state.scratchedZone + c * 10,
        });
      }
    }
  };

  scratchEnd = (e: any) => {
    this.setState({
      isDrawing: false,
    });
  };

  render() {
    return (
      <div className={styles.card_container}>
        <div
          className={styles.card}
          data-tilt
          data-tilt-scale="0.95"
          data-tilt-startY="40"
        >
          <div className={styles.helper_zone}>Gratte ici pour gagner</div>
          <p className={styles.helper_s_zone}>
            Si vous êtes un gagnant, nous vous contacterons bientôt
          </p>
          <div className={styles.scratch_zone}>
            <div className={styles.code}>
              <span>{this.props.item_option}</span>
            </div>
            <canvas
              ref={this.canvasRef}
              id="canvas"
              className={styles.canvas}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ScratchyCard;
