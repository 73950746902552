import { useQuery } from "react-query";
import supabase from "../config/supabase";

interface getGameConfigProps {
  subscriptionId: number;
  gameId: number;
}

const getGameConfig = async (getConfigParams: getGameConfigProps) => {
  const { data, error } = await supabase
    .from("games_config")
    .select()
    .eq("subscription_id", getConfigParams.subscriptionId)
    .eq("game_id", getConfigParams.gameId)
    .single()

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Game config not found");
  }
  return data;
};

export default function useGameConfig(getConfigArgs: getGameConfigProps) {
  return useQuery("gameConfig", () => getGameConfig(getConfigArgs), {
    enabled: !!getConfigArgs.gameId && !!getConfigArgs.subscriptionId
  });
}
