import { Button, Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import Card from "./card";
import styles from "./index.module.scss";
import logo from "../../assets/superverse_logo.png";
import { GameConfig } from "./GameConfig";
import { useGameCanPlay, useGameWins } from "../../api/useGameSessions";
import { RegisterForm } from "../../components";
import { MemoCardItem } from "./GameConfig";
import useSaveGameSession from "../../api/useSaveGameSession";
import useTheme from "@mui/system/useTheme";
import sad from "./sad.png";
import smile from "./smile.png";

// interface CardProps {
//   cardId: number;
//   image: string | URL;
// }

const uniqueCardsArray = [
  {
    id: 1,
    image: logo,
  },
  {
    id: 2,
    image: logo,
  },
  {
    id: 3,
    image: logo,
  },
  {
    id: 4,
    image: logo,
  },
  {
    id: 5,
    image: logo,
  },
  {
    id: 6,
    image: logo,
  },
  {
    id: 7,
    image: logo,
  },
  {
    id: 8,
    image: logo,
  },
  {
    id: 9,
    image: logo,
  },
  {
    id: 10,
    image: logo,
  },
  {
    id: 11,
    image: logo,
  },
  {
    id: 12,
    image: logo,
  },
];

function shuffleCards(array: Array<MemoCardItem>) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

export default function ClasicMemory(props: any) {
  const [openCards, setOpenCards] = useState<Array<number>>([]);
  const [closeFrom, setCloseFrom] = useState(false);
  const [clearedCards, setClearedCards] = useState<Array<number>>([]);
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [userData, setuserData] = useState(null as any);
  const [postAnimation, setPostAnimation] = useState({
    play: false,
    kind: "",
    prize: {} as any,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [gameConfig, setGameConfig] = useState({
    start: false,
    cards: [] as Array<MemoCardItem>,
    memoCardsConfig: {},
    subscription: {},
  });

  const [cantPlay, setCantPlay] = useState({
    value: false,
    message: "",
    setted: false,
  });

  const customConfig = props.gameSession.customGameConfig.game_config;
  const memoCardsConfig = {
    ...customConfig,
  } as GameConfig;

  // console.log("props", props);

  // console.log("memoCardsConfig", memoCardsConfig);

  const {
    data: gameSaveData,
    isLoading: isLoadingSave,
    error: errorSave,
    isSuccess: isSuccessSave,
    mutate: saveGameSession,
  } = useSaveGameSession();

  const {
    data: canPlay,
    isLoading,
    error,
    isSuccess,
  } = useGameCanPlay({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    playerPhone: userData?.phone,
    playerEmail: userData?.email,
    gameCycle: props.gameSession.customGameConfig.game_config.gameTimeCycle,
  });

  useLayoutEffect(() => {
    if (ref.current) {
      if (memoCardsConfig.backgroundColor) {
        ref.current.style.setProperty(
          "background-color",
          memoCardsConfig.backgroundColor,
          "important"
        );
      }
      if (memoCardsConfig.backgroundImage) {
        let tmpStyleBg = `url("${memoCardsConfig.backgroundImage}")`;
        ref.current.style.setProperty(
          "background-image",
          tmpStyleBg,
          "important"
        );
        ref.current.style.setProperty("background-size", "cover", "important");
        ref.current.style.setProperty(
          "background-position",
          "bottom center",
          "important"
        );
      }
    }
  }, []);

  const timeout = useRef(null) as any;

  const disable = () => {
    setShouldDisableAllCards(true);
  };

  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueCardsArray.length) {
      // setShowModal(true);
    }
  };

  const evaluate = () => {
    let isWinner = false;
    let prize = {
      itemId: undefined as any,
      itemName: "",
      itemLink: "",
    };
    enable();
    if (
      gameConfig.cards[openCards[0]].cardId ===
      gameConfig.cards[openCards[1]].cardId
    ) {
      // setClearedCards([...clearedCards, cards[first].id]);
      // setOpenCards([]);
      isWinner = true;
      prize.itemId = gameConfig.cards[openCards[0]].cardId;
      prize.itemName = memoCardsConfig.winningItems.filter(
        (x) => x.itemId == prize.itemId
      )[0].itemName;
    } else {
      isWinner = false;
      // return;
    }
    saveGameSession({
      subscription_id: props.gameSession.subscription.subscriptionData.id,
      game_id: props.gameSession.subscription.gameData.id,
      player_name: userData?.firstName,
      player_last_name: userData?.lastName,
      player_phone: userData?.phone,
      player_email: userData?.email,
      is_winner: isWinner,
      winning_prize_id: prize.itemId,
      winning_prize_name: prize.itemName,
      winning_addional_data: {
        item_link: prize.itemLink || "",
      },
    });
    setPostAnimation({
      play: true,
      kind: isWinner ? "win" : "lose",
      prize: prize,
    });
    // This is to flip the cards back after 500ms duration
    // timeout.current = setTimeout(() => {
    //   setOpenCards([]);
    // }, 500);
  };

  const handleCardClick = (index: number) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null as any;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);

  const {
    data: gameWinsData,
    isLoading: isLoadingWins,
    error: errorWins,
    isSuccess: isSuccessWins,
    refetch: getLastWins,
  } = useGameWins({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    gameCycle: memoCardsConfig.gameTimeCycle as any,
    canPlay: true,
  });

  useEffect(() => {
    if (isSuccess && !canPlay) {
      setCantPlay({
        value: true,
        message: "Tu as déjà joué",
        setted: true,
      });
    }
    if (canPlay) {
      // console.log("Wins :", gameWinsData);
      if (
        gameWinsData?.all &&
        gameWinsData?.all >= memoCardsConfig.winningItems.length
      ) {
        setCantPlay({
          value: true,
          message: "The game just finished",
          setted: true,
        });
      } else {
        // build available wins
        const alreadyWon = Object.keys(gameWinsData?.details);
        // console.log('already won', alreadyWon)
        const availableToWin = memoCardsConfig.winningItems.filter(
          (x) => !alreadyWon.includes(String(x.itemId))
        );
        // console.log('available', availableToWin)
        const randomWin =
          availableToWin[Math.floor(Math.random() * availableToWin.length)];
        // console.log('random Win', randomWin)
        const cardsWithoutWin = shuffleCards(
          memoCardsConfig.cards.filter((x) => x.cardId != randomWin.itemId)
        );

        let cardsChunk = cardsWithoutWin.slice(
          0,
          memoCardsConfig.cardsNumber - 2
        );
        // select from available to win
        var cardsForGame = shuffleCards(
          cardsChunk.concat([
            {
              cardId: randomWin.itemId,
              imageURL: randomWin.itemImage,
              name: randomWin.itemName,
            },
            {
              cardId: randomWin.itemId,
              imageURL: randomWin.itemImage,
              name: randomWin.itemName,
            },
          ])
        );
        setGameConfig({
          cards: cardsForGame,
          start: true,
          memoCardsConfig: memoCardsConfig,
          subscription: props.gameSession.subscription,
        });
      }
    }
  }, [isSuccess, isSuccessWins]);

  const checkIsFlipped = (index: number) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card: MemoCardItem) => {
    return Boolean(clearedCards.includes(card.cardId));
  };

  // const handleRestart = () => {
  //   setClearedCards([]);
  //   setOpenCards([]);
  //   setShowModal(false);
  //   setMoves(0);
  //   setShouldDisableAllCards(false);
  //   // set a shuffled deck of cards
  //   setCards(shuffleCards(uniqueCardsArray.concat(uniqueCardsArray)));
  // };

  const LosePostAnimation = () => {
    return (
      <Dialog
        className={styles.lose}
        open={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={styles.imageHolder}>
            <img src={sad} className={styles.imgSystem} alt="lose" />
          </div>
          <h4>
            {memoCardsConfig.losePanel && memoCardsConfig.losePanel.title
              ? memoCardsConfig.losePanel.title
              : "You was close"}
          </h4>
          <p>
            {memoCardsConfig.losePanel && memoCardsConfig.losePanel.content
              ? memoCardsConfig.losePanel.content
              : "Sorry you was close, but you can try again later."}
          </p>
          {memoCardsConfig.losePanel &&
          memoCardsConfig.losePanel.addionalContent ? (
            <p>
              {memoCardsConfig.losePanel.addionalContent.map((item: any) =>
                item.type === "txt" ? (
                  <>
                    {item.content} <br />
                  </>
                ) : (
                  <>
                    <a
                      href={item.content}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.content}
                    </a>{" "}
                    <br />
                  </>
                )
              )}
            </p>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  };

  const WinPostAnimation = () => {
    return (
      <>
        <Dialog
          className={styles.win}
          open={true}
          disableEscapeKeyDown={true}
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            {memoCardsConfig.logos && memoCardsConfig.logos.length > 0 && (
              <div className={styles.logos}>
                {memoCardsConfig.logos.map((logo: string, index: number) => (
                  <img key={index} src={logo} alt="company logo" />
                ))}
              </div>
            )}
            <div className={styles.imageHolder}>
              {postAnimation.prize.itemImage ? (
                <img
                  src={postAnimation.prize.itemImage}
                  className={styles.imgProduct}
                  alt="win"
                />
              ) : (
                <img src={smile} className={styles.imgSystem} alt="win" />
              )}
            </div>
            <h4>
              {memoCardsConfig.winPanelTitle
                ? memoCardsConfig.winPanelTitle.replace(
                    "$product_name",
                    postAnimation.prize.itemName
                  )
                : "You won"}
            </h4>
            <p>
              {memoCardsConfig.winPanelContents ? (
                memoCardsConfig.winPanelContents.map(
                  (txt: string, index: number) => (
                    <>
                      <span key={index}>{txt}</span>
                      <br />
                    </>
                  )
                )
              ) : (
                <>
                  Congratulations you won -{" "}
                  <b>{postAnimation.prize.itemName}</b>
                </>
              )}
            </p>
            <p>
              {memoCardsConfig.addionalContent
                ? memoCardsConfig.addionalContent.map(
                    (item: any, index: number) =>
                      item.type === "txt" ? (
                        <>
                          <span>{item.content}</span> <br />
                        </>
                      ) : (
                        <>
                          <a
                            href={item.content}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.content}
                          </a>{" "}
                          <br />
                        </>
                      )
                  )
                : null}
            </p>
            <p>Nous vous contacterons pour confirmer votre prix.</p>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <div className={styles.main} ref={ref}>
      <div className={styles.container}>
        {postAnimation.kind.length === 0 &&
          gameConfig.cards.map((card, index) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
                cardBack={memoCardsConfig.cardBack}
              />
            );
          })}
      </div>
      {postAnimation.play && postAnimation.kind === "win" && (
        <WinPostAnimation />
      )}
      {postAnimation.play && postAnimation.kind === "lose" && (
        <LosePostAnimation />
      )}
      <RegisterForm
        getData={setuserData}
        closeForm={closeFrom}
        formTitle={memoCardsConfig.formTitle || undefined}
        description={memoCardsConfig.formDescription || undefined}
        additionalContents={memoCardsConfig.text || undefined}
        customStyle={{ mainColor: memoCardsConfig.backgroundColor }}
      />
    </div>
  );
}
