import { useQuery } from "react-query";
import supabase from "../config/supabase";

const getGames = async () => {
  const { data, error } = await supabase.from("games").select();

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Games not found");
  }

  return data;
};

export default function useGames() {
  return useQuery("games", () => getGames(), {
    cacheTime: 1000 * 60 * 120
  });
}
