import React, { useEffect, useState } from "react";
import SingleCard from "./SingleCard";
import sad from "../sad.svg";
import smile from "../smile.svg";
import styles from "./index.module.scss";
import { Dialog, DialogContent } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { useGameWins } from "../../../api/useGameSessions";
import useSaveGameSession from "../../../api/useSaveGameSession";
import { shuffleArray } from "../utils";
export type CardType = {
  id: number;
  flipped: boolean;
  backImage: string;
  frontImage: string;
  clickable: boolean;
};
export default function MemoryGame(props: any) {
  const [game, setGame] = useState({
    gameCards: [] as Array<any>,
    openCrads: [] as Array<number>,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [choiceOne, setChoiceOne] = useState<CardType | null>(null);
  const [choiceTwo, setChoiceTwo] = useState<CardType | null>(null);
  const [prize, setPrize] = useState(null) as any;
  const [postAnimation, setPostAnimation] = useState({ play: false, kind: "" });
  const [guessInGame, setGuessInGame] = useState(0);

  const {
    data: gameSaveData,
    isLoading: isLoadingSave,
    error: errorSave,
    isSuccess: isSuccessSave,
    mutate: saveGameSession,
  } = useSaveGameSession();

  const {
    data: gameWinsData,
    isLoading: isLoadingWins,
    error: errorWins,
    isSuccess: isSuccessWins,
    refetch: getLastWins,
  } = useGameWins({
    subscriptionId: props.subscription.subscriptionData.id,
    gameId: props.subscription.gameData.id,
    gameCycle: props.memoCardsConfig.gameTimeCycle as any,
    canPlay: true,
  });

  useEffect(() => {
    if (isSuccessWins && !choiceOne && !choiceTwo) {
      let gameCards = props.cards.map((x: any, index: number) => {
        return {
          id: index,
          imageFr: x.imageURL,
          name: x.name,
          clickable: true,
          flipped: false,
        };
      });
      if (gameWinsData && gameWinsData.all >= props.level.maxWinners) {
        let buildDumies = [] as any;
        gameCards.forEach((x: any) => {
          buildDumies.push(x.name);
        });
        let newCard = props.memoCardsConfig.cards.filter(
          (c: any) => !buildDumies.includes(c.name)
        )[0];
        gameCards.push(newCard);
      } else {
        let dupCard = JSON.parse(
          JSON.stringify(
            gameCards[Math.floor(Math.random() * gameCards.length)]
          )
        );
        gameCards.push(dupCard);
      }
      gameCards = shuffleArray(gameCards);
      setGame({
        ...game,
        gameCards,
      });
    }
  }, [props.cards, gameWinsData]);

  // handle a choice
  const handleChoice = (card: any) => {
    if (choiceOne) {
      setChoiceTwo(card);
    } else {
      setChoiceOne(card);
    }
  };

  // win case
  useEffect(() => {
    if (prize != null) {
      saveGameSession({
        subscription_id: props.subscription.subscriptionData.id,
        game_id: props.subscription.gameData.id,
        player_name: props.userData?.firstName,
        player_last_name: props.userData?.lastName,
        player_phone: props.userData?.phone,
        player_email: props.userData?.email,
        is_winner: true,
        winning_prize_id: prize.itemId,
        winning_prize_name: prize.itemName,
        winning_addional_data: {
          item_link: prize.itemLink || "",
        },
      });
      setPostAnimation({ play: true, kind: "win" });
    }
  }, [prize]);

  // compare 2 selected cards
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      if (choiceOne.id === choiceTwo.id) {
        // get all possible wins
        getLastWins({ throwOnError: true }).then((result) => {
          if (result.data) {
            if (result.data.all === 0) {
              // fair pick
              let prize =
                props.level.winningItems[
                  Math.floor(Math.random() * props.level.winningItems.length)
                ];
              setPrize(prize);
            } else {
              // elemination
              let winningItemsWithRules = {} as any;
              props.level.winningItems.forEach((item: any) => {
                winningItemsWithRules[item.itemId] = item.maxWin;
              });
              // [{1 : 2 , 5: 1}]
              let availableToWin = Object.keys(winningItemsWithRules).filter(
                (itemId: any) =>
                  !result.data.details[itemId] ||
                  result.data.details[itemId] < winningItemsWithRules[itemId]
              );
              let idToSet =
                availableToWin[
                  Math.floor(Math.random() * availableToWin.length)
                ];
              let prize = props.level.winningItems.filter(
                (item: any) => item.itemId == idToSet
              )[0];
              setPrize(prize);
            }
          }
        });
        // already game ended
      } else {
        setGuessInGame(guessInGame + 1);
        setTimeout(() => resetChoices(), 500);
      }
    }
  }, [choiceOne, choiceTwo]);

  useEffect(() => {
    if (
      guessInGame >= props.level.guessingNumber &&
      !isSuccessSave &&
      !postAnimation.play
    ) {
      saveGameSession({
        subscription_id: props.subscription.subscriptionData.id,
        game_id: props.subscription.gameData.id,
        player_name: props.userData?.firstName,
        player_last_name: props.userData?.lastName,
        player_phone: props.userData?.phone,
        player_email: props.userData?.email,
        is_winner: false,
        winning_prize_id: undefined,
        winning_prize_name: "",
        winning_addional_data: null,
      });
      setTimeout(() => {
        setPostAnimation({ play: true, kind: "lose" });
      }, 500);
    }
  }, [guessInGame]);

  const resetChoices = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
  };

  const LosePostAnimation = () => {
    return (
      <Dialog
        className={styles.lose}
        open={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={styles.imageHolder}>
            <img src={sad} className={styles.imgSystem} alt="lose" />
          </div>
          <h4>
            {props.memoCardsConfig.losePanel &&
            props.memoCardsConfig.losePanel.title
              ? props.memoCardsConfig.losePanel.title
              : "You was close"}
          </h4>
          <p>
            {props.memoCardsConfig.losePanel &&
            props.memoCardsConfig.losePanel.content
              ? props.memoCardsConfig.losePanel.content
              : "Sorry you was close, but you can try again later."}
          </p>
          {props.memoCardsConfig.losePanel &&
          props.memoCardsConfig.losePanel.addionalContent ? (
            <p>
              {props.memoCardsConfig.losePanel.addionalContent.map(
                (item: any) =>
                  item.type === "txt" ? (
                    <>
                      {item.content} <br />
                    </>
                  ) : (
                    <>
                      <a
                        href={item.content}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.content}
                      </a>{" "}
                      <br />
                    </>
                  )
              )}
            </p>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  };

  const WinPostAnimation = () => {
    return (
      <>
        <Dialog
          className={styles.win}
          open={true}
          disableEscapeKeyDown={true}
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            {props.memoCardsConfig.logos &&
              props.memoCardsConfig.logos.length > 0 && (
                <div className={styles.logos}>
                  {props.memoCardsConfig.logos.map(
                    (logo: string, index: number) => (
                      <img key={index} src={logo} alt="company logo" />
                    )
                  )}
                </div>
              )}
            <div className={styles.imageHolder}>
              {prize.itemImage ? (
                <img
                  src={prize.itemImage}
                  className={styles.imgProduct}
                  alt="win"
                />
              ) : (
                <img src={smile} className={styles.imgSystem} alt="win" />
              )}
            </div>
            <h4>
              {props.level.winPanelTitle
                ? props.level.winPanelTitle.replace(
                    "$product_name",
                    prize.itemName
                  )
                : "You won"}
            </h4>
            <p>
              {props.level.winPanelContents ? (
                props.level.winPanelContents.map(
                  (txt: string, index: number) => (
                    <>
                      <span key={index}>{txt}</span>
                      <br />
                    </>
                  )
                )
              ) : (
                <>
                  Congratulations you won - <b>{prize.itemName}</b>
                </>
              )}
            </p>
            <p>
              {props.level.addionalContent
                ? props.level.addionalContent.map((item: any, index: number) =>
                    item.type === "txt" ? (
                      <>
                        <span>{item.content}</span> <br />
                      </>
                    ) : (
                      <>
                        <a
                          href={item.content}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.content}
                        </a>{" "}
                        <br />
                      </>
                    )
                  )
                : null}
            </p>
            <p>Nous vous contacterons pour confirmer votre prix.</p>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  return (
    <div
      className={
        props.level.name === "Facile"
          ? styles.card_grid_easy
          : props.level.name === "Moyen"
          ? styles.card_grid_med
          : styles.card_grid_hard
      }
    >
      {game.gameCards.map((card, index) => (
        <SingleCard
          key={index}
          card={card}
          cardBack={props.memoCardsConfig.cardBack}
          handleChoice={handleChoice}
          flipped={card === choiceOne || card === choiceTwo}
        />
      ))}
      {postAnimation.play && postAnimation.kind === "win" && (
        <WinPostAnimation />
      )}
      {postAnimation.play && postAnimation.kind === "lose" && (
        <LosePostAnimation />
      )}
    </div>
  );
}
