import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { RegisterForm } from "../../components";
import { useGameCanPlay, useGameWins } from "../../api/useGameSessions";
import useSaveGameSession from "../../api/useSaveGameSession";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Loader from "../../components/Loader";
import styles from "./index.module.scss";
import ScratchyCard from "./ScratchyCard";
import sad from "./sad.svg";
import smile from "./smile.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";

export const ScratchCards = (props: any) => {
  const defaultConfig = props.gameSession.defaultGameConfig;
  const customConfig = props.gameSession.customGameConfig.game_config;
  const [closeFrom, setCloseFrom] = useState(false);
  const [prizeIndex, setPrizeIndex] = useState(-1);
  const [userData, setuserData] = useState(null as any);
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [cantPlay, setCantPlay] = useState({ value: false, message: "" });
  const [postAnimation, setPostAnimation] = useState({ play: false, kind: "" });
  const cardsConfig = {
    ...defaultConfig,
    ...customConfig,
  } as any;

  const {
    data: canPlay,
    isLoading,
    error,
    isSuccess,
  } = useGameCanPlay({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    playerPhone: userData?.phone,
    playerEmail: userData?.email,
    gameCycle: cardsConfig.gameTimeCycle as any,
  });

  const {
    data: gameWinsData,
    isLoading: isLoadingWins,
    error: errorWins,
    isSuccess: isSuccessWins,
  } = useGameWins({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    canPlay: isSuccess,
    gameCycle: cardsConfig.gameTimeCycle as any,
  });

  const {
    data: gameSaveData,
    isLoading: isLoadingSave,
    error: errorSave,
    isSuccess: isSuccessSave,
    mutate: saveGameSession,
  } = useSaveGameSession();

  const selectSegmentNW = (items: Array<any>) => {
    const listPick = items
      .map((item, index) => {
        return { pos: index, win: item.isWinningItem };
      })
      .filter((item) => !item.win);
    return listPick[Math.floor(Math.random() * listPick.length)].pos;
  };
  useLayoutEffect(() => {
    console.log(cardsConfig.backgroundImage);
    if (ref.current) {
      if (cardsConfig.backgroundColor) {
        ref.current.style.setProperty(
          "background-color",
          cardsConfig.backgroundColor,
          "important"
        );
      }
      if (cardsConfig.backgroundImage) {
        let tmpStyleBg = `url("${cardsConfig.backgroundImage}")`;
        ref.current.style.setProperty(
          "background-image",
          tmpStyleBg,
          "important"
        );
        ref.current.style.setProperty("background-size", "cover", "important");
        ref.current.style.setProperty(
          "background-position",
          "top center",
          "important"
        );
      }
    }
  }, []);
  useEffect(() => {
    if (userData && isSuccess && isSuccessWins && prizeIndex === -1) {
      // check if user can play
      if (canPlay) {
        let newPrizeNumber = selectSegmentNW(cardsConfig.items);
        if (
          gameWinsData !== undefined &&
          gameWinsData.all < cardsConfig.maxWinners
        ) {
          newPrizeNumber = Math.floor(Math.random() * cardsConfig.items.length);
        }
        // save the game
        saveGameSession({
          subscription_id: props.gameSession.subscription.subscriptionData.id,
          game_id: props.gameSession.subscription.gameData.id,
          player_name: userData?.firstName,
          player_last_name: userData?.lastName,
          player_phone: userData?.phone,
          player_email: userData?.email,
          is_winner: cardsConfig.items[newPrizeNumber].isWinningItem,
          winning_prize_id: cardsConfig.items[newPrizeNumber].itemId,
          winning_prize_name: cardsConfig.items[newPrizeNumber].option,
          winning_addional_data: {
            item_link: cardsConfig.items[newPrizeNumber].itemLink || "",
          },
        });
        setPrizeIndex(newPrizeNumber);
        setPostAnimation({
          play: false,
          kind: cardsConfig.items[newPrizeNumber].isWinningItem
            ? "win"
            : "lose",
        });
      } else {
        // can't even play
        setCantPlay({ value: true, message: "You already played" });
      }
    }
  }, [userData, gameWinsData, canPlay, isSuccess, isSuccessWins, cardsConfig]);

  // const Animation = () => {
  //   return (
  //     <div className={styles.fireflies_box}>
  //       {[...Array(20)].map((_, i) => (
  //         <div className={styles.fireflies_item} key={i}>
  //           <div className={styles.fireflies_inner}></div>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const LosePostAnimation = () => {
    return (
      <Dialog
        className={styles.lose}
        open={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={styles.imageHolder}>
            <img src={sad} className={styles.imgSystem} alt="lose" />
          </div>
          <h4>
            {cardsConfig.losePanel && cardsConfig.losePanel.title
              ? cardsConfig.losePanel.title
              : "You was close"}
          </h4>
          <p>
            {cardsConfig.losePanel && cardsConfig.losePanel.content
              ? cardsConfig.losePanel.content
              : "Sorry you was close, but you can try again later."}
          </p>
          {cardsConfig.losePanel && cardsConfig.losePanel.addionalContent ? (
            <p>
              {cardsConfig.losePanel.addionalContent.map((item: any) =>
                item.type === "txt" ? (
                  <>
                    {item.content} <br />
                  </>
                ) : (
                  <>
                    <a
                      href={item.content}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.content}
                    </a>{" "}
                    <br />
                  </>
                )
              )}
            </p>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  };

  const WinPostAnimation = () => {
    let prize = cardsConfig.items[prizeIndex];
    return (
      <>
        <Dialog
          className={styles.win}
          open={true}
          disableEscapeKeyDown={true}
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            {cardsConfig.logos && cardsConfig.logos.length > 0 && (
              <div className={styles.logos}>
                {cardsConfig.logos.map((logo: any, index: any) => (
                  <img key={index} src={logo} alt="company logo" />
                ))}
              </div>
            )}
            <div className={styles.imageHolder}>
              {prize.itemImage ? (
                <img
                  src={prize.itemImage}
                  className={styles.imgProduct}
                  alt="win"
                />
              ) : (
                <img src={smile} className={styles.imgSystem} alt="win" />
              )}
            </div>
            <h4>
              {cardsConfig.winPanelTitle
                ? cardsConfig.winPanelTitle.replace(
                    "$product_name",
                    prize.itemFullText
                  )
                : "You won"}
            </h4>
            <p>
              {cardsConfig.winPanelContents ? (
                cardsConfig.winPanelContents.map((txt: any) => (
                  <>
                    {txt} <br />
                  </>
                ))
              ) : (
                <>
                  Congratulations you won - <b>{prize.itemFullText}</b>
                </>
              )}
            </p>
            <p>
              {cardsConfig.addionalContent
                ? cardsConfig.addionalContent.map((item: any) =>
                    item.type === "txt" ? (
                      <>
                        {item.content} <br />
                      </>
                    ) : (
                      <>
                        <a
                          href={item.content}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.content}
                        </a>{" "}
                        <br />
                      </>
                    )
                  )
                : null}
            </p>
            <p>Nous vous contacterons pour confirmer votre prix.</p>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <div className={styles.container} ref={ref}>
      {postAnimation.play && postAnimation.kind === "win" && (
        <WinPostAnimation />
      )}
      {postAnimation.play && postAnimation.kind === "lose" && (
        <LosePostAnimation />
      )}
      {/* <Animation /> */}
      {!cantPlay.value && prizeIndex !== -1 && postAnimation.kind && (
        <ScratchyCard
          item_option={cardsConfig.items[prizeIndex].option}
          postPlay={setPostAnimation}
          kind={postAnimation.kind}
        />
      )}
      {cantPlay.value && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {cantPlay.message} —
        </Alert>
      )}
      <RegisterForm
        getData={setuserData}
        closeForm={closeFrom}
        customStyle={{ mainColor: cardsConfig.backgroundColor }}
        formTitle={cardsConfig.formTitle || undefined}
        description={cardsConfig.formDescription || undefined}
        additionalContents={cardsConfig.text || undefined}
      />
      {isLoading ||
        isLoadingWins ||
        (isLoadingSave && <Loader isTransparent />)}
    </div>
  );
};
