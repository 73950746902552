import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Wheel } from "react-custom-roulette";
import { GameConfig, WhellItem } from "./GameConfig";
import { RegisterForm } from "../../components";
import { useGameCanPlay, useGameWins } from "../../api/useGameSessions";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import { m } from "framer-motion";
import useSaveGameSession from "../../api/useSaveGameSession";
import Loader from "../../components/Loader";
import sad from "./sad.png";
import smile from "./smile.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";

const data = [
  {
    option: "REACT",
    isWinningItem: true,
    itemId: "5551",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "CUSTOM",
    isWinningItem: false,
    itemId: "5552",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "ROULETTE",
    isWinningItem: true,
    itemId: "5553",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "WHEEL",
    isWinningItem: true,
    itemId: "5554",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "REACT",
    isWinningItem: true,
    itemId: "5555",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "CUSTOM",
    isWinningItem: false,
    itemId: "5556",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "ROULETTE",
    isWinningItem: true,
    itemId: "5557",
    itemLink: "https://www.google.com/5554",
  },
  {
    option: "WHEEL",
    isWinningItem: false,
    itemId: "5558",
    itemLink: "https://www.google.com/5554",
  },
];

export const FortuneWheel = (props: any) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(-1);
  const [closeFrom, setCloseFrom] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const ref = useRef<HTMLDivElement>(null);
  const [userData, setuserData] = useState(null as any);
  const [cantPlay, setCantPlay] = useState({
    value: false,
    message: "",
    setted: false,
  });
  const [postAnimation, setPostAnimation] = useState({ play: false, kind: "" });
  // get all config and set the game to start
  // build gonfig for wheel from default and custom
  const defaultConfig = props.gameSession.defaultGameConfig;
  const customConfig = props.gameSession.customGameConfig.game_config;
  const wheelConfig = {
    ...defaultConfig,
    ...customConfig,
  } as GameConfig;

  useLayoutEffect(() => {
    if (ref.current) {
      if (wheelConfig.backgroundColor) {
        ref.current.style.setProperty(
          "background-color",
          wheelConfig.backgroundColor,
          "important"
        );
      }
      if (wheelConfig.backgroundImage) {
        let tmpStyleBg = `url("${wheelConfig.backgroundImage}")`;
        ref.current.style.setProperty(
          "background-image",
          tmpStyleBg,
          "important"
        );
        ref.current.style.setProperty("background-size", "cover", "important");
        ref.current.style.setProperty(
          "background-position",
          "top center",
          "important"
        );
      }
    }
  }, []);

  const {
    data: canPlay,
    isLoading,
    error,
    isSuccess,
  } = useGameCanPlay({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    playerPhone: userData?.phone,
    playerEmail: userData?.email,
    gameCycle: wheelConfig.gameTimeCycle as any,
  });

  const {
    data: gameWinsData,
    isLoading: isLoadingWins,
    error: errorWins,
    isSuccess: isSuccessWins,
  } = useGameWins({
    subscriptionId: props.gameSession.subscription.subscriptionData.id,
    gameId: props.gameSession.subscription.gameData.id,
    gameCycle: wheelConfig.gameTimeCycle as any,
    canPlay: isSuccess,
  });

  const {
    data: gameSaveData,
    isLoading: isLoadingSave,
    error: errorSave,
    isSuccess: isSuccessSave,
    mutate: saveGameSession,
  } = useSaveGameSession();

  const selectSegmentNW = (items: Array<WhellItem>) => {
    const listPick = items
      .map((item, index) => {
        return { pos: index, win: item.isWinningItem };
      })
      .filter((item) => !item.win);
    return listPick[Math.floor(Math.random() * listPick.length)].pos;
  };

  const postGame = () => {
    if (wheelConfig.items[prizeNumber].isWinningItem) {
      setPostAnimation({ play: true, kind: "win" });
    } else {
      setPostAnimation({ play: true, kind: "lose" });
    }
    setMustSpin(false);
  };

  useEffect(() => {
    if (
      userData &&
      isSuccess &&
      prizeNumber === -1 &&
      cantPlay.setted === false
    ) {
      // check if user can play
      if (canPlay) {
        // s-u-c
        if (isSuccessWins) {
          let newPrizeNumber = selectSegmentNW(wheelConfig.items);
          if (
            gameWinsData !== undefined &&
            gameWinsData.all < wheelConfig.maxWinners
          ) {
            // s-u-c-a ;)
            // s-l-p-e
            let builedIndexItems = wheelConfig.items.map((item, index) => {
              return { pos: index, ...item };
            });
            let builedIndexW = builedIndexItems.filter(
              (item) => item.isWinningItem
            );
            let limitsRules = {} as any;
            builedIndexW.forEach((item) => {
              if (item.itemId) {
                limitsRules[item.itemId] = item.maxWins;
              }
            });
            let limitsReached = [] as any;
            Object.keys(gameWinsData.details).forEach((cItemId) => {
              if (gameWinsData.details[cItemId] >= limitsRules[cItemId]) {
                limitsReached.push(cItemId);
              }
            });
            let exItems = builedIndexItems
              .filter((item) => limitsReached.includes(item.itemId))
              .map((item) => item.pos);
            let itemsPicker = builedIndexItems.filter(
              (x) => !exItems.includes(x.pos)
            );
            let chance =
              itemsPicker[Math.floor(Math.random() * itemsPicker.length)].pos;
            newPrizeNumber = chance;
          }
          // save
          setPrizeNumber(newPrizeNumber);
        }
      } else {
        // can't even play
        setCantPlay({
          value: true,
          message: "Tu as déjà joué",
          setted: true,
        });
      }
    }
  }, [
    userData,
    gameWinsData,
    canPlay,
    isSuccess,
    isSuccessWins,
    wheelConfig,
    prizeNumber,
  ]);

  useEffect(() => {
    if (
      canPlay &&
      isSuccess &&
      isSuccessWins &&
      prizeNumber !== -1 &&
      !isSuccessSave
    ) {
      // save
      saveGameSession({
        subscription_id: props.gameSession.subscription.subscriptionData.id,
        game_id: props.gameSession.subscription.gameData.id,
        player_name: userData?.firstName,
        player_last_name: userData?.lastName,
        player_phone: userData?.phone,
        player_email: userData?.email,
        is_winner: wheelConfig.items[prizeNumber].isWinningItem,
        winning_prize_id: wheelConfig.items[prizeNumber].itemId,
        winning_prize_name: wheelConfig.items[prizeNumber].option,
        winning_addional_data: {
          item_link: wheelConfig.items[prizeNumber].itemLink,
        },
      });
      // start animation
      setMustSpin(true);
    }
  }, [prizeNumber, canPlay, isSuccessWins, isSuccess]);

  const Animations = () => {
    return (
      <>
        <ul className={styles.circles}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        {/* <div className={styles.cube}></div>
        <div className={styles.cube}></div>
        <div className={styles.cube}></div>
        <div className={styles.cube}></div>
        <div className={styles.cube}></div>
        <div className={styles.cube}></div> */}
      </>
    );
  };

  const LosePostAnimation = () => {
    return (
      <Dialog
        className={styles.lose}
        open={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={styles.imageHolder}>
            <img src={sad} className={styles.imgSystem} alt="lose" />
          </div>
          <h4>
            {wheelConfig.losePanel && wheelConfig.losePanel.title
              ? wheelConfig.losePanel.title
              : "You was close"}
          </h4>
          <p>
            {wheelConfig.losePanel && wheelConfig.losePanel.content
              ? wheelConfig.losePanel.content
              : "Sorry you was close, but you can try again later."}
          </p>
          {wheelConfig.losePanel && wheelConfig.losePanel.addionalContent ? (
            <p>
              {wheelConfig.losePanel.addionalContent.map((item) =>
                item.type === "txt" ? (
                  <>
                    {item.content} <br />
                  </>
                ) : (
                  <>
                    <a
                      href={item.content}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.content}
                    </a>{" "}
                    <br />
                  </>
                )
              )}
            </p>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  };

  const WinPostAnimation = () => {
    let prize = wheelConfig.items[prizeNumber];
    return (
      <>
        {/* <ConfettiExplosion /> */}
        <Dialog
          className={styles.win}
          open={true}
          disableEscapeKeyDown={true}
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            {wheelConfig.logos && wheelConfig.logos.length > 0 && (
              <div className={styles.logos}>
                {wheelConfig.logos.map((logo, index) => (
                  <img key={index} src={logo} alt="company logo" />
                ))}
              </div>
            )}
            <div className={styles.imageHolder}>
              {prize.itemImage ? (
                <img
                  src={prize.itemImage}
                  className={styles.imgProduct}
                  alt="win"
                />
              ) : (
                <img src={smile} className={styles.imgSystem} alt="win" />
              )}
            </div>
            <h4>{prize.winPanelTitle ? prize.winPanelTitle : "You won"}</h4>
            <p>
              {prize.winPanelContents ? (
                prize.winPanelContents.map((txt) => (
                  <>
                    {txt} <br />
                  </>
                ))
              ) : (
                <>
                  Congratulations you won -{" "}
                  <b>
                    {prize.itemFullText ? prize.itemFullText : prize.option}
                  </b>
                </>
              )}
            </p>
            <p>
              {prize.addionalContent
                ? prize.addionalContent.map((item) =>
                    item.type === "txt" ? (
                      <>
                        {item.content} <br />
                      </>
                    ) : (
                      <>
                        <a
                          href={item.content}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.content}
                        </a>{" "}
                        <br />
                      </>
                    )
                  )
                : null}
            </p>
            <p>Nous vous contacterons pour confirmer votre prix.</p>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const WheelComp = ({ isVisible }: any) => {
    return (
      <m.div
        animate={{ y: ["-1%", "0%"] }}
        id="wheelBox"
        className={styles.wheel_container}
      >
        <div className={styles.wheel_around}>
          <div className={styles.wheel_inner_ping}></div>
        </div>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={wheelConfig.items}
          backgroundColors={wheelConfig.backgroundColors}
          textColors={wheelConfig.textColors}
          fontSize={wheelConfig.fontSize}
          outerBorderColor={wheelConfig.outerBorderColor}
          outerBorderWidth={wheelConfig.outerBorderWidth}
          innerRadius={wheelConfig.innerRadius}
          innerBorderColor={wheelConfig.innerBorderColor}
          innerBorderWidth={wheelConfig.innerBorderWidth}
          radiusLineColor={wheelConfig.radiusLineColor}
          radiusLineWidth={wheelConfig.radiusLineWidth}
          perpendicularText={wheelConfig.perpendicularText}
          textDistance={wheelConfig.textDistance}
          onStopSpinning={() => {
            postGame();
          }}
        />
      </m.div>
    );
  };

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.intro}>
        {/* we hide them for instance , we need more config to control that */}
        {/* <div className={styles.logos}>
          {wheelConfig.logos && (wheelConfig.logos.map(logo => <img src={logo} alt="company logo" />))}
        </div> */}
        {/* {wheelConfig.text &&
          wheelConfig.text.map((tx, i) => <h2 key={i}>{tx.content}</h2>)} */}
      </div>
      <div className={styles.gamebox}>
        {!cantPlay.value &&
          !postAnimation.play &&
          postAnimation.kind !== "lose" && <WheelComp />}
        {cantPlay.value && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {cantPlay.message} —
          </Alert>
        )}
        {postAnimation.play && postAnimation.kind === "win" && (
          <WinPostAnimation />
        )}
        {postAnimation.play && postAnimation.kind === "lose" && (
          <LosePostAnimation />
        )}
        <Animations />
        {prizeNumber === -1 && (
          <RegisterForm
            getData={setuserData}
            closeForm={closeFrom}
            formTitle={wheelConfig.formTitle || undefined}
            description={wheelConfig.formDescription || undefined}
            customStyle={{ mainColor: wheelConfig.backgroundColor }}
            logos={wheelConfig.logos}
          />
        )}
      </div>
      {isLoading ||
        isLoadingWins ||
        (isLoadingSave && <Loader isTransparent />)}
    </div>
  );
};
