import React from "react";
import styles from "./index.module.scss";
import under from "../../assets/under_construction.svg"

export const Health = () => {
  return (
    <div className={styles.container}>
      <img src={under} alt="lost" />
      <p>This sport not ready yet</p>
    </div>
  );
};
