import { useLocation } from "react-router-dom";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export async function cacheImage(imageArray:Array<string>, doneLoadingCallback:Function) {
    const promises = await imageArray.map((image) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                resolve(img);
            };
            img.onerror = (err) => {
                reject(err);
            };
        });
    });
    await Promise.all(promises);
    doneLoadingCallback();
}
