import React from "react";
import classnames from "classnames";
import cardFace from "../../assets/front-card.svg";
import styles from "./index.module.scss";

const Card = ({
  onClick,
  card,
  index,
  isInactive,
  isFlipped,
  isDisabled,
  cardBack
}: any) => {
  const handleClick = () => {
    !isFlipped && !isDisabled && onClick(index);
  };
  const isFlippedStyle = styles[`is-flipped`]
  const isInactiveStyle = styles[`is-inactive`]

  var cardClass = classnames(styles.card,{
    [isFlippedStyle]: isFlipped,
    [isInactiveStyle]: isInactive,
  });

  return (
    <div
      className={cardClass}
      onClick={handleClick}
    >
      <div className={classnames(styles[`card-face`], styles[`card-front-face`])}>
        <img src={cardBack} alt="pokeball"  width={82}/>
      </div>
      <div className={classnames(styles[`card-face`], styles[`card-back-face`])}>
        <img src={isFlipped ? card.imageURL : '#'} alt="pokeball" />
      </div>
    </div>
  );
};

export default Card;
