import { useQuery } from "react-query";
import supabase from "../config/supabase";

interface GameSessionProps {
  subscriptionId: string;
  gameId: string;
  playerPhone?: string;
  playerEmail?: string;
  maxWinners?: number;
  gameCycle?: string;
  canPlay?:boolean
}

const TIME_MAP = {
  day: 24,
  week: "",
  month: "",
  once: 0,
};

const getGameWinners = async ({
  subscriptionId,
  gameId,
  gameCycle,
}: GameSessionProps) => {
  const { data, error } = await supabase
    .from("game_sessions")
    .select(
      `created_at,
      winning_prize_id`
    )
    .eq("is_winner", true)
    .eq("game_id", gameId)
    .eq("subscription_id", subscriptionId)
    .order("created_at", { ascending: true });

  if (error) {
    throw new Error(error.message);
  }
  if (!data || data.length === 0) {
    return { all: 0, details: [] };
  }

  // only one
  if (gameCycle === "once") {
    let result = { all: data.length, details: {} as any };

    data.forEach((x: any) => {
      if (!!result.details[x.winning_prize_id]) {
        result.details[x.winning_prize_id]++;
      } else {
        result.details[x.winning_prize_id] = 1;
      }
    });
    return result;
  }

  // filter date depend on cycle time
  const pick2 = new Date().setHours(0, 0, 0, 0);
  const end_per_day = new Date(pick2);
  const start_per_day = new Date(pick2);
  end_per_day.setDate(end_per_day.getDate() + 1);
  start_per_day.setDate(start_per_day.getDate());
  let winners_per_cycle = [];
  if (gameCycle === "day") {
    winners_per_cycle = data.filter((x) => {
      let gameTime = new Date(x.created_at);
      return gameTime >= start_per_day && gameTime < end_per_day;
    });
  }
  let result = { all: winners_per_cycle.length, details: {} as any };
  winners_per_cycle.forEach((x: any) => {
    if (!!result.details[x.winning_prize_id]) {
      result.details[x.winning_prize_id]++;
    } else {
      result.details[x.winning_prize_id] = 1;
    }
  });
  return result;
};

// we assume that each player can play once
const canPlayGame = async ({
  subscriptionId,
  gameId,
  playerPhone,
  playerEmail,
  gameCycle,
}: GameSessionProps) => {
  const { data, error } = await supabase
    .from("game_sessions")
    .select()
    .eq("game_id", gameId)
    .eq("subscription_id", subscriptionId)
    .or(`player_phone.eq.${playerPhone},player_email.eq.${playerEmail}`);

  if (error) {
    throw new Error(error.message);
  }

  if (!data || data.length === 0) {
    return true;
  }

  // filter date depend on cycle time
  // case 1 user can play only once during the whole event
  if (gameCycle === "once") {
    return false;
  }
  // case 2 filer but faster
  for (let index = 0; index < data.length; index++) {
    let elem = data[index];
    let gameTime = new Date(elem.created_at).getTime();
    let currentTime = Date.now();
    let diff = Math.abs(gameTime - currentTime) / 3600000;
    if (gameCycle === "day" && diff < 24) {
      return false;
    }
    if (gameCycle === "week" && diff < 168) {
      return false;
    }
    if (gameCycle === "month" && diff < 730) {
      return false;
    }
  }
  return true;
};

export function useGameCanPlay({
  subscriptionId,
  gameId,
  playerPhone,
  playerEmail,
  gameCycle,
}: GameSessionProps) {
  return useQuery(
    "gameCanPlay",
    () => canPlayGame({ subscriptionId, gameId, playerPhone, playerEmail, gameCycle }),
    {
      enabled: !!playerPhone,
      cacheTime: 1000 * 60 * 600, // long time cache for gameCanPlay
    }
  );
}

/**
 * @returns return the actual number of winners in the game
 */
export function useGameWins({
  subscriptionId,
  gameId,
  gameCycle,
  canPlay
}: GameSessionProps) {
  return useQuery(
    "gameWins",
    () => getGameWinners({ subscriptionId, gameId, gameCycle }),
    {
      cacheTime: 1000 * 60 * 120,
      enabled: !!canPlay
    }
  );
}
