import { Route } from "react-router-dom";

export interface RouteWithSubRoutesProps {
  path?: string | readonly string[] | undefined;
  component?: any;
  routes?: Array<RouteWithSubRoutesProps>;
  gameSession?: any;
}

export const RouteWithSubRoutes = (route: RouteWithSubRoutesProps) => {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component
          {...props}
          routes={route.routes}
          gameSession={route.gameSession}
        />
      )}
    />
  );
};
